/* eslint-disable quotes */
import "lightcase/src/js/lightcase.js";
import "select2/dist/js/select2.min.js";
import 'flickity/dist/flickity.pkgd.min.js';
import Flickity from 'flickity';

var shrinkHeader = 5;

export default {
  init() {
    // JavaScript to be fired on all pages

    Flickity.defaults.dragThreshold = 3;
    removePfromIframe();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

  // Cookie Policy Set up
  //check to see if the submited cookie is set, if not check if the popup has been closed, if not then display the popup
  if( getCookie('popupCookie') != 'submited'){
    if(getCookie('popupCookie') != 'closed' ){
      $('.cookie-wrp').css("display", "block").hide().fadeIn();
    }
  }

  $('a.close').click(function(){
    $('.cookie-wrp').fadeOut();
    //sets the coookie to one minute (.00069444444) if the popup is closed (whole numbers = days)
    setCookie( 'popupCookie', 'closed', 1 );
  });

  $('a.accept').click(function(){
    $('.cookie-wrp').fadeOut();
    //sets the coookie to five minutes (.0034722222 ) if the popup is submited (whole numbers = days)
    setCookie( 'popupCookie', 'submited', 100);
  });
  // Cookie Policy Set up

    $('.button-accordion').on('click', function() {
      let buttonId = $(this).data("id");
      let elems = document.querySelectorAll(".collapse.show-map");

      setTimeout(function() {
        [].forEach.call(elems, function(el) {
          el.className = el.className.replace(/\bshow-map\b/, "");
        });
      }, 10);

        if($('#'+buttonId).hasClass('show-map')) {
          $('#'+buttonId).removeClass('show-map');
        } else {
          $('#'+buttonId).addClass('show-map');
        }

    });

    jQuery(".form-dropdown").select2({
      dropdownParent: $('.select2-wrapper'),
    });

    jQuery("a[data-rel^=lightcase]").lightcase({
      slideshowAutoStart: false,
    });

    $('.header .mobile-menu').click(function(){
      $('body').toggleClass('mobile-menu-active');
    });

    gallerySectionSlider();
    cardSectionSlider();
    wrapButtons();
    mobileMenuAccordion();
    twoColWithSliderSection();
    twoColWithSliderSection2();
    twoColClientSection();
    featuredBlogSection();
    processSection();
    downloadFormOption();
    checkPreFooterExistence();
  },
};

$(window).on('resize', function () {
  twoColWithSliderSection();
  twoColWithSliderSection2();

  $('.cards-section .card-wrp, .two-col-slider-section .two-col-slider-wrp, .clients-section .clients-wrp, .post-listing-section .post-wrp-inner, .process-section .process-wrp').flickity('resize');

  $('.two-col-slider-section .two-col-slider-wrp .flickity-viewport').css('height', '0');
});

// To avoid unnessary resize() fires in mobile
var cachedWidth = $(window).width();
$(window).resize(function(){
  var newWidth = $(window).width();
  if(newWidth !== cachedWidth){
    //DO RESIZE HERE

    // mobileMenuAccordion();

    cachedWidth = newWidth;
  }
});

$(window).on('load', function () {
  $('.cards-section .card-wrp, .two-col-slider-section .two-col-slider-wrp, .two-col-slider-section.sides-changed .two-col-slider-wrp, .clients-section .clients-wrp, .post-listing-section .post-wrp-inner, .process-section .process-wrp').flickity(
    'resize'
  );
});


$(window).scroll(function () {
  var scroll = getCurrentScroll();
  if (scroll >= shrinkHeader) {
    $('body, .header-container').addClass('sticky-header');
  } else {
    $('body, .header-container').removeClass('sticky-header');
  }
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}

function mobileMenuAccordion() {
  if(!window.matchMedia('(min-width: 1200px)').matches) {
    $('.header-wrp .menu-item-has-children:not(.nav-btn)').each(function() {
      $(this).find('> a').on('click', function(e){
        e.preventDefault();
        e.stopPropagation();
        // $(this).parents('.menu-item-has-children').toggleClass('dropdown-active');
        // $(this).parents('.menu-item-has-children').siblings().removeClass('dropdown-active');
        // $(this).parents('.menu-item-has-children').siblings().children('.sub-menu').slideUp();
        // $(this).parents('.menu-item-has-children').children('.sub-menu').slideToggle();
        if ($(this).parents('.menu-item-has-children').parents('.menu-item-has-children').length == 0) {
          $(this).parents('.menu-item-has-children').toggleClass('dropdown-active');
          $(this).parents('.menu-item-has-children').siblings().removeClass('dropdown-active');
          $(this).parents('.menu-item-has-children').siblings().children('.sub-menu').slideUp();
          $(this).parents('.menu-item-has-children').children('.sub-menu').slideToggle();
        } else if ($(this).parents('.menu-item-has-children').parents('.menu-item-has-children').length == 1) {
          $(this).parent().toggleClass('dropdown-active');
          $(this).parent().siblings().removeClass('dropdown-active');
          $(this).parent().siblings().children('.sub-menu').slideUp();
          $(this).parent().children('.sub-menu').slideToggle();
        }
      });
    });
  } else {
    $('.header-wrp .menu-item-has-children a').unbind('click');
  }
}

function gallerySectionSlider() {
  let $gallerySection = $('.gallery-section .gallery-wrapper');

  if ($gallerySection.length > 0) {
    var cellAlignValue = ''

    if (matchMedia('screen and (max-width: 768px)').matches ) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $gallerySection.flickity({
      contain: false,
      pageDots: false,
      prevNextButtons: true,
      adaptiveHeight: false,
      wrapAround: false,
      watchCSS: true,
      cellAlign: cellAlignValue,
      initialIndex: 3,
    });
  }
}

function cardSectionSlider() {

  let $cardSectionSlider = $('.cards-section .card-wrp');

  if ($cardSectionSlider.length > 0) {

    $cardSectionSlider.flickity({
      pageDots: true,
      prevNextButtons: false,
      wrapAround: true,
      watchCSS: true,
      cellAlign: 'left',
    });
  }
}

//wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor
function wrapButtons() {
  $(".wrap-btns").each(function( ) {
    var wrapper = $(this).find(".btn").unwrap();

    wrapper.wrapAll( "<div class='button-wrapper' />");
  });
}
//wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor

function twoColWithSliderSection() {
  let $twoColWithSliderSection = $('.two-col-slider-section:not(.sides-changed) .two-col-slider-wrp');
  let $twoColWithSliderItems = $twoColWithSliderSection.find('.slider-item');

  var cellAlignValue = '',
      SliderItemLength;
  if (matchMedia('screen and (max-width: 767px)').matches ) {
    cellAlignValue = 'center';
    SliderItemLength = $twoColWithSliderItems.length > 1;
  } else {
    cellAlignValue = 'left';
    SliderItemLength = $twoColWithSliderItems.length > 2;
  }

  if (SliderItemLength) {
    $twoColWithSliderSection.removeClass('less-items');
    $twoColWithSliderSection.flickity({
      contain: false,
      pageDots: true,
      prevNextButtons: true,
      adaptiveHeight: false,
      wrapAround: true,
      cellAlign: cellAlignValue,
    });
  } else {
    $twoColWithSliderSection.flickity('destroy');
    $twoColWithSliderSection.addClass('less-items');
  }
}

function twoColWithSliderSection2() {
  let $twoColWithSliderSection2 = $('.two-col-slider-section.sides-changed .two-col-slider-wrp');
  let $twoColWithSlider2Items = $twoColWithSliderSection2.find('.slider-item');

  var cellAlignValue = '',
      SliderItemLength;
  if (matchMedia('screen and (max-width: 767px)').matches ) {
    cellAlignValue = 'center';
    SliderItemLength = $twoColWithSlider2Items.length > 1;
  } else {
    cellAlignValue = 'right';
    SliderItemLength = $twoColWithSlider2Items.length > 2;
  }

  if (SliderItemLength) {
    $twoColWithSliderSection2.removeClass('less-items');
    $twoColWithSliderSection2.flickity({
      contain: false,
      pageDots: true,
      prevNextButtons: true,
      adaptiveHeight: false,
      wrapAround: true,
      cellAlign: cellAlignValue,
    });
  } else {
    $twoColWithSliderSection2.flickity('destroy');
    $twoColWithSliderSection2.addClass('less-items');
  }
}

function twoColClientSection() {
  let $twoColClientSection = $('.clients-section .clients-wrp');

  if ($twoColClientSection.length > 0) {
    $twoColClientSection.flickity({
      contain: false,
      pageDots: true,
      prevNextButtons: false,
      adaptiveHeight: false,
      wrapAround: true,
      cellAlign: 'left',
      watchCSS: true,
    });
  }
}

function featuredBlogSection() {
  let $featuredBlogSection = $('.post-listing-section .post-wrp-inner');

  if ($featuredBlogSection.length > 0) {
    $featuredBlogSection.flickity({
      contain: false,
      pageDots: true,
      prevNextButtons: false,
      adaptiveHeight: false,
      wrapAround: true,
      cellAlign: 'center',
      watchCSS: true,
    });
  }
}

function processSection() {
  let $processSection = $('.process-section .process-wrp');

  if ($processSection.length > 0) {
    $processSection.flickity({
      contain: false,
      pageDots: true,
      prevNextButtons: false,
      adaptiveHeight: false,
      wrapAround: true,
      cellAlign: 'center',
      watchCSS: true,
    });
  }
}

// contact 7 form download option

function downloadFormOption() {
  if ($('.cta-two-col-section').length > 0) {
    var wpcf7Elm = document.querySelector( '.download-form' );

    wpcf7Elm.addEventListener( 'wpcf7mailsent', function() {
      $(".download-pdf")[0].click();
    }, false );
  }
}

// Cookie Policy Popup
function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
// Cookie Policy Popup

function removePfromIframe() {
  $('.one-column-section .other-content p > iframe').unwrap();
}

function checkPreFooterExistence() {
  if ($('.pre-footer').length == '0') {
    $('body').addClass('no-pre-footer');
  }
}