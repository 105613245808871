import 'flickity/dist/flickity.pkgd.min.js';
import Flickity from 'flickity';

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    megaNavHomeSeparate();
    homeBannerGallery1();
    homeBannerGallery2();
    homeBannerGallery3();
    homeBannerGallery4();
    homeClientSection();
    homeMobileMenuAccordion();
  },
};

// To avoid unnessary resize() fires in mobile
var cachedWidth = $(window).width();
$(window).resize(function(){
  var newWidth = $(window).width();
  if(newWidth !== cachedWidth){
    //DO RESIZE HERE

    homeMobileMenuAccordion();

    cachedWidth = newWidth;
  }
});

function megaNavHomeSeparate() {
  $('.home-banner-section .mega-menu-mobile .accordion-wrp').attr('id','mobileMenu');

  $('.home-banner-section .mega-menu-mobile .accordion-wrp .menu-content').each(function() {
    $(this).attr('data-parent','#mobileMenu');
  });

  var i = 1;

  $('.home-banner-section .mega-menu-mobile .accordion-wrp .menu-title').each(function() {
    $(this).attr('data-target','#mobileMenuContent' + i);
    $(this).siblings('.menu-content').attr('id', 'mobileMenuContent' + i)
    i++;
  });
}

function homeBannerGallery1() {
  let $homeBannerGallery1 = $('.home-banner-section .gallery-1');

  if ($homeBannerGallery1.length > 0) {
    $homeBannerGallery1.flickity({
      contain: false,
      pageDots: false,
      prevNextButtons: false,
      adaptiveHeight: false,
      cellAlign: 'center',
      wrapAround: true,
      setGallerySize: false,
      autoPlay: 2500,
      lazyLoad: true,
    });
  }
}

function homeBannerGallery2() {
  let $homeBannerGallery2 = $('.home-banner-section .gallery-2');

  if ($homeBannerGallery2.length > 0) {
    $homeBannerGallery2.flickity({
      contain: false,
      pageDots: false,
      prevNextButtons: false,
      adaptiveHeight: false,
      cellAlign: 'center',
      wrapAround: true,
      setGallerySize: false,
      autoPlay: 5500,
      initialIndex: 1,
      lazyLoad: true,
    });
  }
}

function homeBannerGallery3() {
  let $homeBannerGallery3 = $('.home-banner-section .gallery-3');

  if ($homeBannerGallery3.length > 0) {
    $homeBannerGallery3.flickity({
      contain: false,
      pageDots: false,
      prevNextButtons: false,
      adaptiveHeight: false,
      cellAlign: 'center',
      wrapAround: true,
      setGallerySize: false,
      autoPlay: 3500,
      initialIndex: 2,
      lazyLoad: true,
    });
  }
}

function homeBannerGallery4() {
  let $homeBannerGallery4 = $('.home-banner-section .gallery-4');

  if ($homeBannerGallery4.length > 0) {
    $homeBannerGallery4.flickity({
      contain: false,
      pageDots: false,
      prevNextButtons: false,
      adaptiveHeight: false,
      cellAlign: 'center',
      wrapAround: true,
      setGallerySize: false,
      autoPlay: 8500,
      initialIndex: 3,
      lazyLoad: true,
    });
  }
}

function homeClientSection() {
  let $homeClientSection = $('.home-clients-section .clients-wrp');

  if ($homeClientSection.length > 0) {
    // Play with this value to change the speed
    let tickerSpeed = 1;

    let flickity = null;
    let isPaused = false;
    const slideshowEl = document.querySelector('.home-clients-section .clients-wrp');

    const update = () => {
      if (isPaused) return;
      if (flickity.slides) {
        flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
        flickity.selectedIndex = flickity.dragEndRestingSelect();
        flickity.updateSelectedSlide();
        flickity.settle(flickity.x);
      }
      window.requestAnimationFrame(update);
    };

    const pause = () => {
      isPaused = true;
    };

    const play = () => {
      if (isPaused) {
        isPaused = false;
        window.requestAnimationFrame(update);
      }
    };

    flickity = new Flickity(slideshowEl, {
      autoPlay: false,
      prevNextButtons: false,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      selectedAttraction: 0.015,
      friction: 0.25,
    });

    flickity.x = 0;

    slideshowEl.addEventListener('mouseenter', pause, false);
    slideshowEl.addEventListener('focusin', pause, false);
    slideshowEl.addEventListener('mouseleave', play, false);
    slideshowEl.addEventListener('focusout', play, false);

    flickity.on('dragStart', () => {
      isPaused = true;
    });

    update();
  }
}

// home mobile menu accordion
function homeMobileMenuAccordion() {
  if(!window.matchMedia('(min-width: 1200px)').matches) {
    $('.home-banner-section .menu-item-has-children').each(function() {
      $(this).find('> a').on('click', function(e){
        e.preventDefault();
        e.stopPropagation();
        $(this).parents('.menu-item-has-children').toggleClass('dropdown-active');
        $(this).parents('.menu-item-has-children').siblings().removeClass('dropdown-active');
        $(this).parents('.menu-item-has-children').siblings().children('.sub-menu').slideUp();
        $(this).parents('.menu-item-has-children').children('.sub-menu').slideToggle();
      });
    });
  } else {
    $('.home-banner-section .menu-item-has-children a').unbind('click');
  }
}
